.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 1px;
}

:root {
  font-size: small;
}

/*
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
}*/

.autocomplete-container {
  width: 300px;
}

div .yNHHyP-marker-view {
  margin: 0;
  padding: 0;
  --pico-border-color: none !important;
}

.infowindow-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
}

.grid-container > div:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #ddd; /* Vertical line color */
}
