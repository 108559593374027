.suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  width: 100%; /* Set width to match the input box */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #eee;
}

.autocomplete-container-vol {
  position: relative; /* To ensure the suggestion list is positioned correctly */
  width: 30%; /* Ensure the container takes full width */
}

.autocomplete-input {
  margin-bottom: 0 !important;
}
