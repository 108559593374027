.grid-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr; /* First and third column take equal space, middle is separator */
  align-items: center; /* Aligns the grid items vertically */
  gap: 1rem; /* Optional: space between items */
}

.vertical-sep {
  width: 1px; /* Set the separator width to be as small as possible */
  height: 100%; /* Full height of the container */
  background-color: #ddd; /* Light gray color */
  margin: 0 auto; /* Center the separator */
}

.checkbox {
  padding-bottom: 20px;
  padding-top: 10px;
}
