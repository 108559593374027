/* Thumbnail Shower Container */
.thumbnail-shower {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  overflow-x: auto;
  width: 100%; /* Ensures the container spans full width */
}

/* Thumbnail Row - ensures the row height is consistent */
.thumbnail-row {
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch; /* Ensures equal height across the thumbnails */
  min-height: 200px; /* Fixed minimum height to ensure the row always has a height */
}

/* Each Thumbnail Item */
.thumbnail-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure thumbnails take up full height of the row */
  min-width: 200px; /* Ensure the thumbnails have a minimum size */
}

/* Individual Thumbnail */
.thumbnail {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image inside Thumbnail */
.thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* Delete Button */
.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.7); /* Red background with transparency */
  color: white;
  font-size: 18px; /* Adjusted font size for visibility */
  border: none;
  width: 30px; /* Fixed width for the square background */
  height: 30px; /* Fixed height for the square background */
  display: flex;
  justify-content: center; /* Centers the "X" horizontally */
  align-items: center; /* Centers the "X" vertically */
  border-radius: 5px; /* Slightly rounded corners for a more polished look */
  cursor: pointer;
  z-index: 10; /* Ensure the button appears above the image */
}

.delete-btn:hover {
  background-color: red; /* Darker red on hover */
}

/* Download Button */
.download-btn {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(0, 128, 0, 0.5); /* Green with transparency */
  color: white;
  font-size: 18px;
  border: none;
  padding: 5px 8px;
  width: 24px; /* Square button width */
  height: 24px; /* Square button height */
  z-index: 10; /* Ensure it appears above the image */
  opacity: 0.7;
  display: flex;
  justify-content: center; /* Center the "↓" vertically */
  align-items: center; /* Center the "↓" horizontally */
}

.download-btn:hover {
  background-color: rgba(0, 128, 0, 0.7); /* Stronger green on hover */
  opacity: 1; /* Fully visible on hover */
}

/* Modal Overlay (If Used) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.full-size-image {
  width: 100%;
  height: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.close-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
